import { css } from 'lit-element';

export default css`

  scrollable-component {
    height:calc(100vh - 270px);
    --scrollbar-width: 10px;
    --content-padding: 0 20px 0 0;
    --viewport-overflow-x: hidden;
  }
  
  form {
    position:relative;
  }

  .labol {
    --label-width:6rem;
    --gap-width: 1rem;
  }

  .labol_large {
    --label-width:15rem;
    --gap-width: 1rem;
  }

  .labol_medium {
    --label-width:11rem;
    --gap-width: 1rem;
  }

  .labol + .labol {
    margin-top: var(--sl-spacing-medium);
  }

  .labol::part(form-control) {
    display: grid;
    grid: auto / var(--label-width) 1fr;
    gap: var(--sl-spacing-3x-small) var(--gap-width);
    align-items: center;
  }

  .labol::part(form-control-label) {
    text-align: right;
  }

  .labol::part(form-control-help-text) {
    grid-column-start: 2;
    text-align:left;
  }

  .hidden {
    display:none;
  }

  sl-tab-group.main::part(tabs) {
    
  }

  sl-textarea::part(textarea) { 
    font-size:0.9em;
  }

  sl-tab::part(base) {
    padding: 0px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:5px;
    padding-top:5px;
    border-radius:0px; 
    overflow:hidden;
    font-size:0.85em;
  }

  sl-tab-group.main sl-tab::part(base) {
    padding-bottom:10px;
    padding-top:10px;
  }


  sl-tab-panel::part(base) {
    padding-right:5px;
    padding-left:0px;
    padding-bottom:0px;
  }

  sl-switch {
    font-size: 0.8em;
  }

  sl-switch.labol::part(base) {
    display: grid;
    grid: auto / var(--label-width) 1fr;
    gap: var(--sl-spacing-3x-small) var(--gap-width);
    align-items: center;
    text-align: right;
  }

  sl-switch.labol::part(label) {
    display: contents;
    order: 2;
    text-align:right;
  }


  .labol::part(form-control-label) {
    text-align: right;
  }

  sl-switch.labol::part(control) {
    order: 1;
  }

  sl-select::part(display-input) {
    font-family: Calibri;
    font-size:1em;
  }

  sl-option::part(base) {
    padding-top:2px;
    padding-bottom:2px;
  }

  sl-option::part(label) {
    font-size: 0.9em;
    padding-top:2px;
    padding-bottom:2px;
    font-family: Calibri;
  }

  sl-select[size="small"] > sl-option::part(label) {
    font-size: 0.9em;
    font-family: Calibri;
  }

  .number {
    --label-width: 70px;
    --gap-width: 1rem;
  }

  .number + .number {
    /*margin-top: var(--sl-spacing-medium);*/
  }

  .number::part(form-control) {
    display: grid;
    grid: auto / var(--label-width) 1fr;
    gap: var(--sl-spacing-3x-small) var(--gap-width);
    align-items: center;
  }

  .number::part(form-control-label) {
    text-align: left;
  }

  .number::part(form-control-help-text) {
    grid-column-start: 2;
  }

  .number::part(form-control-input) {
    width:80px;
    float: right;
  }

  sl-dropdown {
    text-align:left;
  }

  sl-menu-item m-icon {
    font-size:1.5em;
  }

  sl-menu-item m-icon[name="delete"] {
    color:var(--sl-color-danger-500);
  }

  sl-menu-item::part(base) {
    font-size:0.9em;
    padding:2px;
    padding-left:5px;
  }

  sl-menu-item::part(checked-icon) {
    display:none;
  }

  m-icon[name="more_vert"] {
    cursor:pointer;
  }

  .input_with_button {
    width:100%;
    gap:5px;
    display: grid;
    grid-template-columns: 1fr 47px;
    height:49px;
    overflow:hidden;
  }

  sl-input::part(input) {
    font-family: Calibri;
  }

  .bold {
    font-weight:bold;
  }

  small {
    font-size: 0.8em;
    margin-left: -15px;
  }
`;