import { LitElement } from 'lit-element';
import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { Logger } from 'Utils';
import App from 'App';

class BaseElement extends LitElement {
  static get properties() {
    return {
      _lang: { type: String },
      debug: { type: Boolean },
    }
  }

  static get translations() {
    return [
      {
        english:{
          translation: {
            close:'Close',
            please_wait:'Please wait ...',
          }
        },
        french:{
          translation: {
            close:'Fermer',
            please_wait:'Veuillez patienter ...',            
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this._handleScroolbar = false;
    this._logLevel = 'debug';
    this._lang = i18next.language;
    this._onLanguageChanged = this._onLanguageChanged.bind(this);
    this._onLayoutChanged = this._onLayoutChanged.bind(this);
    this._handleIntersection = this._handleIntersection.bind(this);
    this._onThemeChange = this._onThemeChange.bind(this);
  }

  get translations() {
    if (!Array.isArray(this.constructor.translations) || !this.constructor.translations.length) return null;
    this._translations = this.mergeElements({}, this.constructor.translations);
    return this._translations;
  }

  mergeElements(target, ...sources) {
    for (const source of sources) {
      if (Array.isArray(source)) {
        // Si c'est un tableau, on le fusionne récursivement
        source.forEach(item => this.mergeElements(target, item));
      } else if (typeof source === 'object' && source !== null) {
        // Si c'est un objet, on itère sur ses clés
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            const targetValue = target[key];
            const sourceValue = source[key];

            // Si les deux valeurs sont des objets, on fusionne récursivement
            if (typeof targetValue === 'object' && targetValue !== null && 
              typeof sourceValue === 'object' && sourceValue !== null) {
              this.mergeElements(targetValue, sourceValue);
            } else {
              // Sinon, on remplace simplement la valeur cible par la source
              target[key] = sourceValue;
            }
          }
        }
      } else {
          // Si c'est un élément simple, on l'ajoute à l'objet
          target[source] = source;
      }
    }
    return target;
  }

  _createLogger() {
    this._log = new Logger(this.constructor.name, this._logLevel, this.debug);
  }
  
  _i18init() {
    this._i18nInstance = i18next.createInstance();
    this._i18nInstance
      .use(ChainedBackend)
      .init({
        debug:false,
        resources: this._translations,
        fallbackLng: App.config.language,
        /*
        detection: {
          order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
          caches: ['localStorage', 'cookie']
        },
        */
        interpolation: {
          escapeValue: false
        },
        backend: {
          // LocalStorageBackend has not effect if http backend is not used
          // @TODO: implement backend ;)
          backends: [ LocalStorageBackend ],
          backendOptions: [
            { expirationTime: 7 * 24 * 60 * 60 * 1000 }
          ]
        }
      });
  }

  connectedCallback() {
    super.connectedCallback();
    this._createLogger();
    if (this.translations) {
      window.addEventListener('language-changed', this._onLanguageChanged);
      this._i18init();
    }
    
    window.addEventListener('layout-change', this._onLayoutChanged);
    window.addEventListener('theme-change', this._onThemeChange);
  }
  
  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this._onLanguageChanged);
    window.removeEventListener('layout-change', this._onLayoutChanged);
    window.addEventListener('theme-change', this._onThemeChange);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  async visibleCallback() {
    // override me
  }

  async hiddenCallback() {
    // override me
  }

  async firstUpdated() {
    super.firstUpdated();
    if (!this.observer) {
      this.observer = new IntersectionObserver(this._handleIntersection, {
        root: null, // Utiliser le viewport comme root
        threshold: 0.1 // Déclencher lorsque 10% de l'élément est visible
      });
      this.observer.observe(this);
    }
  }

  //@TODO: refactor qs => query
  qs(query, all = false) {
    if (all) {
      return this.shadowRoot.querySelectorAll(query) || this.querySelectorAll(query) || document.querySelectorAll(query);
    }
    return this.shadowRoot.querySelector(query) || this.querySelector(query) || document.querySelector(query);
  }

  _handleIntersection(entries) {
    entries.forEach(async entry => {
      if (entry.isIntersecting) {
        //this._log.debug('_handleIntersection', 'visible', entry.target);
        await this.visibleCallback();
      } else {
        //this._log.debug('_handleIntersection', 'hidden', entry.target);
        await this.hiddenCallback();
      }
    });
  }

  _onThemeChange() {
    // override me
  }

  _onLanguageChanged(ev) {
    // override me
    this._lang = ev.detail.language;
    this._i18nInstance.changeLanguage(this._lang);
  }

  _tl(key, attributes) {
    if (!this._i18nInstance) return key;
    const txt = this._i18nInstance.t(key, attributes);
    if (txt === key) {
      console.warn(`${this.constructor.name}: translation not found for key '${key}' '${this._lang}'`);
    }
    return txt;
  }

  async _onLayoutChanged() {
    this._log.debug('_onLayoutChanged');
    // this is required for apexchart (by example)
    clearTimeout(this._layoutTimeout);
    this._layoutTimeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300)
  }
}

export default BaseElement;